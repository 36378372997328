import { Snackbar } from "../../components/Snackbar";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import classes from "./showRefreshSnackbar.module.scss";

const SNACKBAR_KEY = "network-refresh";

export const showRefreshSnackbar = () => {
  closeSnackbar(SNACKBAR_KEY);
  setTimeout(() => {
    enqueueSnackbar("", {
      key: SNACKBAR_KEY,
      persist: true,
      content: (
        <Snackbar
          onClose={() => closeSnackbar(SNACKBAR_KEY)}
          message={<span className={classes.snackbarMessage}>Network connection was lost.</span>}
          uiActions={[{ label: " Refresh", action: () => window.location.reload() }]}
          dismissible
        />
      ),
    });
  }, 1000);
};